<template>
  <div>
    <!-- Filter Bar -->
    <FilterBar />

    <!-- Table -->
    <vuetable data-path="influencers"
          ref="vuetable"
          @vuetable:pagination-data="onPaginationData"
          pagination-path="pagination"
          :api-url="apiUrl"
          :fields="fields"
          :http-options="httpOptions"
          :css="css.table"
          :per-page="10"
          :append-params="moreParams"
          :query-params="{
            sort: 'sort',
            page: 'page',
            perPage: 'perPage'
          }"
          track-by="userId">

      <!-- <template v-slot:video="props">
        <div class="text-center buttons-influencers">
          <a v-if="props.rowData.videoUrl" :href="props.rowData.videoUrl" target="_blank" class="btn btn-link">
            <i class="fas fa-eye"></i>
          </a>
          <button v-if="props.rowData.videoUrl" @click="openEditModal(props.rowData.userId)" class="btn btn-warning">
            <i class="fas fa-edit"></i> 
          </button>
          <button v-else @click="openUploadModal(props.rowData.userId)" class="btn btn-primary">
            <i class="fas fa-upload"></i>
          </button>
        </div>
      </template> -->
      <!-- <template v-slot:video="props">
        <div class="text-center buttons-influencers">
          <button @click="goToProfile(props.rowData.userId)" class="btn btn-info">
            <i class="fas fa-user"></i> Ver Perfil
          </button>
        </div>
      </template> -->
      <template v-slot:video="props">
        <router-link :to="`/admin/influencers/${props.rowData.userId}`">
          <b-button
            class="m-1"
            title="View Playlist"
            type="button"
            size="sm"
            variant="primary"
            @click.prevent="goToProfile(props.rowData.userId, props.rowData)">
            <b-icon icon="eye" aria-hidden="true"></b-icon>
          </b-button>
        </router-link>
      </template>

    </vuetable>

    <!-- Pagination -->
    <div class="d-flex align-items-center justify-content-end">
      <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
      <vuetable-pagination 
        ref="pagination" 
        @vuetable-pagination:change-page="onChangePage"
        :css="css.pagination"
      />
    </div>

    <!-- Upload Modal -->
    <div v-if="showUploadModal">
      <div class="modal-backdrop"></div>
      <div class="modal show" style="display: block;">
        <button type="button" class="btn-close" @click="closeUploadModal">
          <i class="fas fa-times"></i>
        </button>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Upload Video for  {{ selectedInfluencer.nickname }}</h5>
              <button type="button" class="btn-close" @click="closeUploadModal"></button>
            </div>
            <div class="modal-body">
              <input type="file" @change="handleFileUpload" accept="video/*" />
              
              <!-- Video Preview -->
              <div v-if="videoPreview" class="mt-3">
                <h6>Video Preview: </h6>
                <video :src="videoPreview" controls width="100%"></video>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-primary" @click="uploadVideo">Upload Video</button>
              <button class="btn btn-secondary" @click="closeUploadModal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Loading -->
    <AppLoaderAdmin :isLoading="isLoading" />
  </div>
</template>

<script>
  import Vue from 'vue';
  import axios from 'axios';
  import TableStyle from '../table-bootstrap-css.js';

  const Vuetable = () => import('vuetable-2/src/components/Vuetable');
  const VuetablePagination = () =>
  import('vuetable-2/src/components/VuetablePagination');
  const VuetablePaginationInfo = () =>
  import('vuetable-2/src/components/VuetablePaginationInfo');
  const FilterBar = () => import('./FilterBar.vue');

  export default {
    name : 'InfluencerList',
    data() {
      return {
        isLoading   : false,
        apiUrl : process.env.VUE_APP_ROOT_API +
          'api/broadcast/influencers',
        moreParams  : {},
        css         : TableStyle,
        httpOptions : {
          headers : {
            'Authorization' : `Bearer ${this.$store.state.auth.accessToken}`,
          },
        },
        fields : [
          {
            name       : 'userId',
            title      : 'ID',
            sortField  : 'user_id',
            direction  : 'asc',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name      : 'email',
            title     : 'Email',
            sortField : 'email',
            direction : 'asc',
          },
          {
            name      : 'nickname',
            title     : 'Nickname',
            sortField : 'nickname',
            direction : 'asc',
          },
          {
            name      : 'firstname',
            title     : 'First Name',
            sortField : 'firstname',
            direction : 'asc',
          },
          {
            name      : 'lastname',
            title     : 'Last Name',
            sortField : 'lastname',
            direction : 'asc',
          },
          {
            name      : 'timezone',
            title     : 'Timezone',
            sortField : 'timezone',
            direction : 'asc',
          },
          {
            name: '__slot:video',  // Cambia a esta línea para habilitar el slot
            title: 'Video',
            titleClass: 'text-center',
            dataClass: 'text-center',
          }
        ],
        showUploadModal: false,
        selectedInfluencer: null,
        file: null,
        videoPreview: null,
      }
    },
    components : {
      'AppLoaderAdmin' : () => import('@/components/layout/AppLoaderAdmin'),
      FilterBar,
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
    },
    methods : {

      /**
       * Transform data
       *
       * @param data
       * @return {object}
       */
      transform(data) {
        console.log('data', data);
        const transformed = {};

        transformed.pagination = {
          total         : data.total,
          per_page      : data.perPage,
          current_page  : data.currentPage,
          last_page     : data.lastPage,
          next_page_url : data.nextPageUrl,
          prev_page_url : data.prevPageUrl,
          from          : data.from,
          to            : data.to,
        };

        transformed.influencers = [];

        for (let i = 0; i < data.data.length; i++) {
          transformed.influencers.push({
            userId    : data.data[i].user_id,
            email     : data.data[i].email,
            nickname  : data.data[i].nickname,
            firstname : data.data[i].firstname,
            lastname  : data.data[i].lastname,
            timezone  : data.data[i].timezone,
            videosInfluencer : data.data[i].videos_influencer,
          });
        }

        console.log(transformed, ' {ilga}');
        
        return transformed;
      },

      /**
       * Set Pagination Info
       * @param paginationData
       */
      onPaginationData(paginationData) {
        this.$refs.pagination.setPaginationData(paginationData);
        this.$refs.paginationInfo.setPaginationData(paginationData);
      },

      /**
       * Change page
       * @param page
       */
      onChangePage(page) {
        this.$refs.vuetable.changePage(page);
      },

      /**
       * On Filter Set
       * @param filterText
       */
      onFilterSet(filterText) {
        this.moreParams = {
          'filter' : filterText,
        };
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },

      /**
         * On Reset Filter
         */
      onFilterReset() {
        this.moreParams = {};
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },

      /**
       * Update Influencer's List
       */
      onAddInfluencer() {
        this.$refs.vuetable.reload();
      },






      // -------------------------- ********************
      goToProfile(userId, influencerData) {
        // Elimina el registro anterior del influencer en localStorage
        localStorage.removeItem(`influencer_${userId}`);

        // Guarda los nuevos datos del influencer en localStorage
        localStorage.setItem(`influencer_${userId}`, JSON.stringify(influencerData));

        // Navega a la página del perfil del influencer usando el parámetro 'id'
        this.$router.push({
          path: `/admin/influencers/${userId}`,
          params: { influencerId: userId }
        });
        // this.$router.push({ name: 'influencer-profile'});
      },


      /// ----------------------------
      openUploadModal(userId) {
        // Encuentra el influencer por el userId
        this.selectedInfluencer = this.$refs.vuetable.tableData.find(
          inf => inf.userId === userId
        );
        this.showUploadModal = true;
      },
      openEditModal(userId) {
        // Encuentra el influencer por el userId
        this.selectedInfluencer = this.$refs.vuetable.tableData.find(
          inf => inf.userId === userId
        );
        this.showUploadModal = true;  // Reutiliza el modal de subir para editar
      },
      closeUploadModal() {
        this.showUploadModal = false;
        this.selectedInfluencer = null;
        this.file = null;
        this.videoPreview = null;  // Limpiar la previsualización del video
      },
      handleFileUpload(event) {
        this.file = event.target.files[0];
        if (this.file) {
          this.videoPreview = URL.createObjectURL(this.file);  // Crear URL temporal para previsualizar
        }
      },
      async uploadVideo() {

        if (!this.file) {
          alert('Please select a file to upload');
          return;
        }

        this.isLoading = true;

        const formData = new FormData();
        formData.append('file', this.file);
        formData.append('project_id', '9365980');

        try {
          const response = await axios.post('https://upload.wistia.com', formData, {
            headers: {
              Authorization: `Bearer 74dcb98e1cc2fb852d30bb2c0d59cf13c0538ae6f141f704d5748945db275d4f`,
              'Content-Type': 'multipart/form-data',
            },
          });


          await this.saveVideoToBackend(this.selectedInfluencer.userId, response.data.hashed_id);
          this.$refs.vuetable.reload();
          this.closeUploadModal();
        } catch (error) {
          console.error('Error al subir el video:', error);
        }
      },
      async saveVideoToBackend(userId, videoId) {
        

        const videoUrl = `https://fast.wistia.net/embed/iframe/${videoId}`;

        try {
          this.$http.post('api/user/setVideoUrl', {
            userId: userId,
            videoUrl: videoUrl
          }).then(response => {
            this.$parent.$emit('reload-table');
            this.$emit('close');
            this.$notify({
              group : 'notif',
              type  : 'success',
              title : 'Success!',
              text  : 'Added Video successfully!',
            });
            this.closeUploadModal();
            this.$refs.vuetable.reload();
          }).finally(() => {
            this.isLoading = false;
          });
        } catch (error) {
          console.error('Error al guardar el video en el backend:', error);
        }
      },



    },
    mounted() {
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData));
      this.$events.$on('filter-reset', () => this.onFilterReset());
      this.$events.$on('add-influencer', () => this.onAddInfluencer());
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/influencers/influencer-list";
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1040;
  }

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  width: 90%;
  max-width: 500px;
  background-color: white;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  height: auto;
}

.btn.btn-link {
  background: #22bb33;
  color: white;
}
.buttons-influencers a, .buttons-influencers button{
  margin: 0 5px;
}

.btn-close {
    background: none;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .btn-close i {
    color: #000; /* Cambia el color del icono si es necesario */
  }

  .btn-close:hover i {
    color: #ff0000; /* Cambia el color al pasar el cursor (opcional) */
  }

  .modal-backdrop {
  opacity: 0.9 !important;
}
</style>